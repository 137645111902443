import Axios from "axios";
import Store from "@/store";

const axiosConfig = {
  baseURL: "/v1/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const httpClient = Axios.create(axiosConfig);

httpClient.interceptors.request.use((config) => {
  const token = Store.state.login.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export function get(url, params) {
  return httpClient.get(url, params);
}

export function post(url, params) {
  return httpClient.post(url, params);
}

export function put(url, params) {
  return httpClient.put(url, params);
}

export function del(url) {
  return httpClient.delete(url);
}

export function patch(url, params) {
  return httpClient.patch(url, params);
}
