import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import en from "vuetify/lib/locale/en";
import it from "vuetify/lib/locale/it";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#14C6FF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        lightblue: "#14c6FF",
        yellow: "#FFCF00",
        pink: "#FF1976",
        orange: "#FF8657",
        magenta: "#C33AFC",
        darkblue: "#1E2D56",
        gray: "#909090",
        neutralgray: "#9BA6C1",
        green: "#2ED47A",
        red: "#FF5c4E",
        darkblueshade: "#308DC2",
        lightgray: "#BDBDBD",
        lightpink: "#FFCFE3",
        white: "#FFFFFF",
        gold: "#aa956f",
        blueLight: "#119da4",
        blueMedium: "#0c7489",
        blueDark: "#13505b",
        dark: "#040404",
        light: "#f8f9fa",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
  lang: {
    locales: {
      en: en,
      it: it,
    },
    current: "it",
  },
});
