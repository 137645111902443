import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import LoginModule from "./modules/login";
import OptionsModule from "./modules/options";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: {
      active: false,
      response: "",
      color: "",
    },
    skeletonLoading: {
      active: false,
      type: "",
    },
  },
  getters: {
    getSnackbar: (state) => {
      return state.snackbar;
    },
    getSkeletonLoading: (state) => {
      return state.skeletonLoading;
    },
  },
  mutations: {
    _setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
    _setSkeletonLoading(state, skeletonLoading) {
      state.skeletonLoading = skeletonLoading;
      if (skeletonLoading.active == false) {
        let overlays = document.getElementsByClassName("v-overlay");
        Array.from(overlays).forEach((overlay) => {
          overlay.remove();
        });
      }
    },
  },
  actions: {},
  modules: {
    login: LoginModule,
    options: OptionsModule,
  },
  plugins: [
    createPersistedState({
      paths: ["login", "options"],
    }),
  ],
});
