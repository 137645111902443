<template>
  <v-app class="h-100">
    <div class="d-flex h-100 overflow-auto" :class="{ 'pa-5': showSidebar }">
      <TheNavbar
        v-if="showSidebar"
        id="bodyTag"
        class="h-100"
        style="width: 300px"
      />
      <v-main class="flex-default h-100" style="width: calc(100% - 300px)">
        <template v-if="skeletonLoading.active">
          <v-skeleton-loader
            class="h-100"
            :loading="true"
            :type="skeletonLoading.type"
          />
        </template>
        <router-view v-else />
      </v-main>
      <TheSnackbar />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { get, post } from "./utility/httpClient";
import { useTitle } from "@vueuse/core";
import { HandleApiCallResponse } from "@/utility/generic.js";
import TheNavbar from "@/components/TheNavbar.vue";
import TheSnackbar from "./components/TheSnackbar.vue";

export default {
  name: "App",
  components: {
    TheNavbar,
    TheSnackbar,
  },
  setup() {
    const title = useTitle("Sartoria Esthés - ERP");
    if (process.env.VUE_APP_ENVR === "dev") {
      title.value = "[DEV] " + title.value;
    } else if (process.env.VUE_APP_ENVR === "qa") {
      title.value = "[QA] " + title.value;
    }
  },
  computed: {
    ...mapGetters({
      skeletonLoading: "getSkeletonLoading",
      userIsLogged: "login/userIsLogged",
      getOptions: "options/getOptions",
      getUserId: "login/getUserId",
    }),
    showSidebar() {
      return !this.$route.meta.hideSidebar && this.userIsLogged;
    },
  },
  watch: {
    getOptions: {
      handler(newValue) {
        this.updateOptions(newValue);
      },
      deep: true,
    },
  },
  async created() {
    if (this.getUserId) await this.getDbOptions();
    this.setSkeletonLoading({
      active: false,
      type: "",
    });
  },
  methods: {
    ...mapMutations({
      setSkeletonLoading: "_setSkeletonLoading",
    }),
    ...mapActions("options", {
      setOptions: "setOptions",
    }),
    async getDbOptions() {
      try {
        let { data } = await get(`users/${this.getUserId}/options`);
        let optionsToSet = {
          appZoomClass: data.appZoomClass,
          activeLocale: data.activeLocale,
          tablesSort: data.tablesSort,
        };

        this.setOptions(optionsToSet);
      } catch (error) {
        HandleApiCallResponse.handleCommonError(error, "login");
      }
    },
    updateOptions: async function (newValue) {
      let newOptions = {
        appZoomClass: newValue.appZoomClass,
        activeLocale: newValue.activeLocale,
        tablesSort: newValue.tablesSort,
      };
      await post("users/options/edit", {
        userId: this.getUserId,
        options: newOptions,
      });
    },
  },
};
</script>

<style lang="scss">
@use "scss/style";

.v-application--wrap {
  min-height: auto !important;
}
</style>
