<template>
  <div class="h-100 d-flex align-center">
    <v-navigation-drawer
      id="navbar"
      permanent
      floating
      class="h-100 medium-pill shadow-on ma-5"
    >
      <v-list-item class="px-5 mb-3" style="height: 160px">
        <v-list-item-content class="h-100 w-100">
          <v-img
            contain
            src="../assets/esthes-logo.svg"
            class="h-100 w-auto"
            alt=""
          />
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-5"></v-divider>
      <v-list
        class="overflow-y-auto overflow-x-hidden mt-3"
        style="height: calc(100% - 200px)"
        rounded
      >
        <div v-for="(item, index) in getNavbarItems" :key="index" class="mb-1">
          <v-list-item
            v-if="!item.items"
            :to="{ name: item.route }"
            color="gold"
          >
            <v-list-item-icon
              ><v-icon color="gold">{{ item.icon }}</v-icon></v-list-item-icon
            ><v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else color="gold">
            <template #activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <template #prependIcon>
              <v-icon color="gold">{{ item.icon }}</v-icon>
            </template>
            <v-list-item
              v-for="(subItem, jndex) in item.items"
              :key="jndex"
              :to="{ name: subItem.route }"
            >
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon color="gold">{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <template #append>
        <v-divider class="mx-5"></v-divider>
        <v-list rounded class="mx-1 mb-2">
          <v-list-item>
            <div class="w-100 d-flex align-center">
              <v-list-item-title
                class="text-body-2 font-weight-bold text-truncate"
              >
                {{ userName }}
              </v-list-item-title>
              <v-btn icon :to="{ name: 'options' }" color="gold">
                <v-icon>settings</v-icon>
              </v-btn>
            </div>
          </v-list-item>
          <v-list-item @click="onLogout">
            <v-list-item-icon>
              <v-icon color="gold">logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("navbar.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../router/index";

export default {
  name: "TheNavbar",
  data: () => ({
    openedItems: [],
    selectedLocale: null,
    navOptions: {
      appZoomClass: 3,
      activeLocale: "it",
    },
  }),
  computed: {
    ...mapGetters({
      userIsLogged: "login/userIsLogged",
      userName: "login/getUserName",
      getUserId: "login/getUserId",
      getActiveLocale: "options/getActiveLocale",
      getOptions: "options/getOptions",
    }),
    getNavbarItems() {
      return [
        {
          title: `${this.$t("navbar.home")}`,
          icon: "home",
          route: "home",
        },
        {
          title: `${this.$t("navbar.sale")}`,
          icon: "storefront",
          route: "clients",
          items: [
            {
              title: `${this.$t("navbar.clients")}`,
              icon: "people",
              route: "clients",
            },
            {
              title: `${this.$t("navbar.orders")}`,
              icon: "shopping_bag",
              route: "orders",
            },
            {
              title: `${this.$t("navbar.price-list")}`,
              icon: "sell",
              route: "products",
            },
            {
              title: `${this.$t("navbar.gantt")}`,
              icon: "date_range",
              route: "gantt",
            },
          ],
        },
        {
          title: `${this.$t("navbar.purchase")}`,
          icon: "conveyor_belt",
          items: [
            {
              title: `${this.$t("navbar.suppliers")}`,
              icon: "forklift",
              route: "suppliers",
            },
            {
              title: `${this.$t("navbar.products")}`,
              icon: "inventory_2",
              route: "purchase-products",
            },
            {
              title: `${this.$t("navbar.orders")}`,
              icon: "shopping_cart",
              route: "purchases",
            },
            {
              title: `${this.$t("navbar.warehouses")}`,
              icon: "warehouse",
              route: "warehouses",
            },
            {
              title: `${this.$t("navbar.inventory")}`,
              icon: "fact_check",
              route: "inventory",
            },
          ],
        },
        {
          title: `${this.$t("navbar.marketing")}`,
          icon: "groups_2",
          items: [
            {
              title: `${this.$t("navbar.ranking")}`,
              icon: "star_rate",
              route: "rankings",
            },
            {
              title: `${this.$t("navbar.notifications")}`,
              icon: "mail",
              route: "notifications",
            },
          ],
        },
        {
          title: `${this.$t("navbar.users")}`,
          icon: "badge",
          last: true,
          route: "users",
        },
      ];
    },
    locales() {
      return [
        { locale: "it", title: this.$t("navbar.italian") },
        { locale: "en", title: this.$t("navbar.english") },
      ];
    },
  },
  watch: {
    navOptions: {
      handler() {
        this.setActiveLocale(this.navOptions.activeLocale);
        this.setAppZoomClass(this.navOptions.appZoomClass);
      },
      deep: true,
    },
  },
  created() {
    this.setNavOptions();
  },
  methods: {
    ...mapActions("options", {
      setActiveLocale: "setActiveLocale",
      setAppZoomClass: "setAppZoomClass",
    }),
    ...mapActions("login", {
      logout: "logout",
    }),
    onLogout: async function () {
      this.logout();
      router.push({ name: "login" });
    },
    setNavOptions: function () {
      this.navOptions = {
        appZoomClass: this.getOptions.appZoomClass,
        activeLocale: this.getOptions.activeLocale,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../scss/utilities/colors";
#navbar {
  background-color: white;
}

::v-deep .v-navigation-drawer__content {
  overflow: hidden;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

::v-deep .v-expansion-panel-header {
  .v-icon {
    flex: none;
    margin-right: 32px;
  }
  .v-expansion-panel-header__icon {
    margin-left: auto;
    margin-right: 0;
    .v-icon {
      margin-right: 0;
    }
  }
}
</style>
