import { setI18nLanguage } from "@/i18n";
export default {
  namespaced: true,
  state: {
    options: {
      // PER I DEFAULT GUARDA DTO.OPTIONS NEL BACKEND
      appZoomClass: undefined,
      activeLocale: undefined,
      tablesSort: {},
    },
    ganttOptions: {
      headerTypeSelected: 1,
      screenType: 2,
      toggleAutoCompleteDate: true,
      ganttOrdersPerPage: 10,
      ganttSelected: null,
    },
  },
  getters: {
    getAppZoomClass: (state) => {
      return state.options.appZoomClass;
    },
    getActiveLocale: (state) => {
      return state.options.activeLocale;
    },
    getTableSort: (state) => {
      return state.options.tablesSort;
    },
    getOptions: (state) => {
      return state.options;
    },
    getGanttOptions: (state) => {
      return state.ganttOptions;
    },
    getGanttSelected: (state) => {
      return state.ganttOptions.ganttSelected;
    },
  },
  mutations: {
    _setAppZoomClass(state, payload) {
      state.options.appZoomClass = payload;

      let classi = document.body.classList;
      while (classi.length > 0) {
        classi.remove(classi.item(0));
      }
      document.body.classList.add(`zoom-${payload}`);
    },
    _setActiveLocale(state, payload) {
      state.options.activeLocale = payload;
      setI18nLanguage(payload);
    },
    _setTableSort(state, payload) {
      state.options.tablesSort = payload;
    },
    /* GANTT --------------------------- */
    _setGanttOptions(state, payload) {
      state.ganttOptions = payload;
    },
    _setGanttSelected(state, payload) {
      state.ganttOptions.ganttSelected = payload;
    },
  },
  actions: {
    setAppZoomClass({ commit }, payload) {
      commit("_setAppZoomClass", payload);
    },
    setActiveLocale({ commit }, payload) {
      commit("_setActiveLocale", payload);
    },
    setTableSort({ commit }, payload) {
      commit("_setTableSort", payload);
    },
    setOptions({ commit }, payload) {
      commit("_setAppZoomClass", payload.appZoomClass);
      commit("_setActiveLocale", payload.activeLocale);
      commit("_setTableSort", payload.tablesSort);
    },
    /* GANTT */
    setGanttOptions({ commit }, payload) {
      commit("_setGanttOptions", payload);
    },
    setGanttSelected({ commit }, payload) {
      commit("_setGanttSelected", payload);
    },
  },
};
