<template>
  <v-snackbar
    v-model="snackbar.active"
    timeout="5000"
    :color="snackbar.color"
    right
  >
    {{ snackbar.response }}
    <template #action="{ attrs }">
      <v-icon v-bind="attrs" color="white" @click="snackbar.active = false">
        close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "TheSnackbar",
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
};
</script>
