import { post } from "../../utility/httpClient";

export default {
  namespaced: true,
  state: {
    userLogged: false,
    userName: "",
    userId: undefined,
    token: "",
  },
  getters: {
    userIsLogged: (state) => {
      return state.userLogged;
    },
    getUserName: (state) => {
      return state.userName;
    },
    getUserId: (state) => {
      return state.userId;
    },
  },
  mutations: {
    SET_LOGIN(state, bool) {
      state.userLogged = bool;
    },
    SET_USERNAME(state, userName) {
      state.userName = userName;
    },
    SET_USER_ID(state, id) {
      state.userId = id;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      const { data } = await post("auth/login", {
        username: username,
        password: password,
      });
      const { user, token } = data;
      const userName = `${user.name} ${user.surname}`;
      commit("SET_LOGIN", true);
      commit("SET_USERNAME", userName);
      commit("SET_USER_ID", user.id);
      commit("SET_TOKEN", token);
    },
    logout({ commit }) {
      commit("SET_LOGIN", false);
      commit("SET_USERNAME", null);
      commit("SET_USER_ID", null);
      commit("SET_TOKEN", null);
    },
  },
};
